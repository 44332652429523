.App {
  color: #121212;
  background-color: #ffffff;
  text-align: center;
  font-family: "Spartan", sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #121212;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.react-icon {
  color: #121212 !important;
}

.spacer {
  color: #121212 !important;
}
.uk-timeline .uk-timeline-item .uk-card {
  max-height: 300px;
}

.uk-timeline .uk-timeline-item {
  display: flex;
  position: relative;
}

.uk-timeline .uk-timeline-item::before {
  background: #dadee4;
  content: "";
  height: 100%;
  left: 19px;
  position: absolute;
  top: 20px;
  width: 2px;
  z-index: -1;
}

.uk-timeline .uk-timeline-item .uk-timeline-icon .uk-badge {
  margin-top: 20px;
  width: 40px;
  height: 40px;
}

.uk-timeline .uk-timeline-item .uk-timeline-content {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 0 0 0 1rem;
}
.purple-hg {
  background-color: #c885da;
}
.yellow-hg {
  background-color: #feff04;
}
.alignleft {
  float: left;
}
.alignright {
  float: right;
}
.certiprof-iframe {
  height: 270px;
}
